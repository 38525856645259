<template>
  <v-sheet min-height="70vh" rounded elevation="2">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="10" md="8" lg="6" class="mt-md-15 mb-5">
          <!-- shareType:file -->
          <v-slide-y-reverse-transition mode="out-in">
            <v-card
              key="file"
              :disabled="disabled"
              v-if="shareType == 'file'"
              rounded="lg"
              hover
              style="border: 2px dashed #aaa"
              @click.stop="fileCardOnClick"
              @drop.prevent="onDrop"
              @dragover.prevent
            >
              <v-row>
                <v-col>
                  <v-btn
                    color="info"
                    class="rounded-lg float-right"
                    small
                    @click.stop="shareTextBtnOnClick"
                    ><v-icon left>mdi-form-textbox-password</v-icon>
                    分享文本</v-btn
                  >
                </v-col>
              </v-row>
              <div class="pb-16 pt-10">
                <v-row justify="center">
                  <v-icon size="80">mdi-file-upload</v-icon>
                </v-row>
                <v-row class="pt-5 text--secondary" justify="center">
                  <div>拖放文件到此 / 点击添加文件</div>
                </v-row>
              </div>

              <v-row justify="center">
                <v-col cols="10" md="6">
                  <v-expand-transition>
                    <v-file-input
                      key="fileInput"
                      id="fileInput"
                      v-show="file != null"
                      v-model="file"
                      dense
                      outlined
                      prepend-icon
                      label="文件列表"
                      :loading="uploading"
                      @click.native.stop
                    ></v-file-input>
                  </v-expand-transition>
                </v-col>
              </v-row>
            </v-card>

            <!-- type:text -->
            <v-card
              key="text"
              :disabled="disabled"
              v-else-if="shareType == 'text'"
              rounded="lg"
              hover
              ripple
              style="border: 2px dashed #aaa"
              @touchend.stop
            >
              <v-row>
                <v-col>
                  <v-btn
                    color="info"
                    class="rounded-lg float-right"
                    small
                    @click.stop="shareType = 'file';text=null"
                    ><v-icon left>mdi-file-link</v-icon> 分享文件</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    solo
                    :loading="uploading"
                    counter="9999"
                    label="请输入要分享的文本"
                    v-model="text"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card>
            <!-- shareType==finish -->
            <v-card
              dark
              color="#3F51B5"
              key="finish"
              :disabled="disabled"
              v-else
              rounded="lg"
              hover
              ripple
              style="border: 2px dashed #faa"
              @touchend.stop
            >
              <v-row>
                <v-col class="ml-2 mt-2">
                  <div class="text-h5 d-flex align-center">
                    <v-icon left color="#82b1ff" size="48"
                      >mdi-check-decagram</v-icon
                    >
                    <div>分享成功！</div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="">
                  <v-textarea
                    id="shareInfo"
                    color="white"
                    outlined
                    label="分享信息"
                    rows="4"
                    v-model="shareInfo"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-reverse-transition>
        </v-col>
      </v-row>
      <v-row class="px-md-5">
        <v-col cols="12" sm="6" md="3" class="pb-0">
          <v-select
            v-model="selectedTime"
            :items="saveTime"
            outlined
            dense
            label="存放时间"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <v-row>
            <v-col>
              <v-switch
                class="mt-0 mt-md-4"
                v-model="deleteAfterReading"
                inset
                label="阅后即焚"
              ></v-switch>
            </v-col>
            <v-col>
              <v-switch
                class="mt-0 mt-md-4"
                v-model="hasZip"
                inset
                label="打包上传"
              ></v-switch>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text--secondary"> * 上传大小上限为 100MB </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" md="4"
          ><v-btn
            @click="onUpload"
            color="primary"
            :disabled="disabled"
            rounded
            x-large
            block
            class="mt-md-5 mb-md-5"
            >{{ shareType == "finish" ? "返回" : "立即分享" }}
            {{ percentCompleted == 100 ? "" : percentCompleted + "%" }}</v-btn
          ></v-col
        >
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>

export default {
  data() {
    return {
      shareType: "file",
      deleteAfterReading: false,
      saveTime: [
        { text: "存放 4 小时", value: 4 },
        { text: "存放到今天", value: 1 },
      ],
      selectedTime: 1,
      file: null,
      text: null,
      uploading: false,
      disabled: false,
      percentCompleted: 100,
      hasZip: false,
      shareInfo: "no info",
    };
  },
  methods: {
    fileCardOnClick() {
      let fileInput = window.document.getElementById("fileInput");
      fileInput.click();
    },
    shareTextBtnOnClick() {
      if (this.file) {
        this.file = null;
        setTimeout(() => {
          this.shareType = "text";
        }, 200);
        return true;
      }
      this.shareType = "text";
    },
    onUpload() {
      // 返回到分享文件card
      if (this.shareType == "finish") {
        if (this.text!=null) {
          this.shareType = "text";
          return false;
        }
        this.shareType = "file";
        return false;
      }

      this.uploading = true;
      this.disabled = true;
      /*
      this.percentCompleted = 0;
      let handle = setInterval(() => {
        this.percentCompleted += 5;
        if (this.percentCompleted == 100) {
          this.uploading = false;
          this.disabled = false;
          this.file = null;
          setTimeout(() => {
            this.shareType = "finish";
          }, 200);
          clearInterval(handle);
        }
      }, 200);
      */

      if (!this.hasData()) {
        this.uploading = false;
        this.disabled = false;
        return false;
      }

      let filePath = URL.createObjectURL(this.file);
      // console.log(filePath)
      console.log(this.file);
      // window.file=this.file
      let cloudPath = this.file.name;
      window.uploadFile({
        filePath,
        cloudPath,
        headers:{
          contentEncoding:"utf-8"
        },
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent);
          this.percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
        success: (e) => {
          console.log(e);

          window.uni.bcc.call({
            url: "api/index/addShareInfo",
            data: {
              url: e.fileID,
              name: this.file.name,
              text:this.text,
              size: this.file.size,
              saveTime: this.selectedTime,
              deleteAfterReading: this.deleteAfterReading,
            },
            success: (res) => {
              console.log(res);
              this.shareInfo = `与你分享文件：${this.file.name}，打开链接：https://share.hookin.fun/，点击接收并输入密码：${res.sharePwd}，${this.selectedTime==1?'今天':this.selectedTime+' 小时'}后过期。`;
              this.file = null;
              this.disabled = false;
              this.uploading = false;
              setTimeout(() => {
                this.shareType = "finish";
                setTimeout(() => {
                  document.getElementById("shareInfo").select();
                  document.execCommand("Copy");
                }, 600);
              }, 200);
            },
            fail: (res) => {
              console.log(res);
              this.disabled = false;
              this.uploading = false;
            },
          });
        },
        fail: (res) => {
          console.log(res);
          this.disabled = false;
          this.uploading = false;
        },
      });
    },
    hasData() {
      // if in the text card, share text to the file
      if (this.shareType == "text") {
        switch (this.text) {
          case null:
          case "":
            return false;
          default:
            this.file = new Blob([this.text], { type: "text/plain;charset=utf-8" });
            this.file.name = new Date().toLocaleDateString() + "的分享.txt";
            return true;
        }
      } else if (this.shareType == "file" && this.file == null) {
        return false;
      }
      return true;
    },
    onDrop(e){
      console.log(e.dataTransfer.files);
      this.file=e.dataTransfer.files[0];
    },
    enter(){
      console.log(1)
    }
  },
};
</script>
