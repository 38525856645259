<template>
    <v-expansion-panels multiple popout>
              <v-expansion-panel v-for="n in 10" :key="n">
                <v-expansion-panel-header>
                  header{{ n }}
                  <v-spacer></v-spacer>
                  <v-responsive max-width="110"><v-icon size="1rem" class="mr-2">mdi-sleep</v-icon>2018-01-12</v-responsive>
                  
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col class="title">24h active</v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-sheet color="mainbg" elevation="2" rounded>
                            <v-sparkline
                            :value="[123, 12, 334, 23]"
                            auto-line-width
                            smooth
                            stroke-linecap="round"
                          >
                            <template v-slot:label="item">{{
                              item.value
                            }}</template>
                          </v-sparkline>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col class="title">24h active</v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-sheet color="mainbg" elevation="2" rounded>
                            <v-sparkline
                            :value="[123, 12, 334, 23]"
                            auto-line-width
                            smooth
                            stroke-linecap="round"
                          >
                            <template v-slot:label="item">{{
                              item.value
                            }}</template>
                          </v-sparkline>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      >Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Odit dolore aliquam, vitae voluptatum minima obcaecati
                      placeat animi numquam molestiae labore fugiat, reiciendis
                      eos? A in aspernatur rem, consequatur assumenda
                      quos.</v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col
                      >Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Odit dolore aliquam, vitae voluptatum minima obcaecati
                      placeat animi numquam molestiae labore fugiat, reiciendis
                      eos? A in aspernatur rem, consequatur assumenda
                      quos.</v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col
                      >Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Odit dolore aliquam, vitae voluptatum minima obcaecati
                      placeat animi numquam molestiae labore fugiat, reiciendis
                      eos? A in aspernatur rem, consequatur assumenda
                      quos.</v-col
                    >
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>