<template>
  <v-sheet min-height="70vh" rounded elevation="2">
    <v-container>
      <!-- 功能区 -->
      <v-row>
        <v-col class="title"> <v-icon left>mdi-apps-box</v-icon>功能区 </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-for="(funcBtnItem, index) in onShowBtns"
          :key="index"
        >
          <v-card :to="funcBtnItem.to" color="primary" dark>
            <v-card-title
              ><v-icon left>{{ funcBtnItem.icon }}</v-icon
              >{{ funcBtnItem.title }}</v-card-title
            >
            <v-card-subtitle>
              <v-col>
                {{ funcBtnItem.tip }}
              </v-col>
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-pagination v-model="page" :length="Math.ceil(funcBtns.length/pageItem)"></v-pagination>
        </v-col>
      </v-row>
      <!-- 我关注的 -->
      <v-row>
        <v-col class="title">
          <v-icon left>mdi-tag-text-outline</v-icon>
          我关注的
          <v-btn color="primary" @click="init">刷新</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
              <v-expansion-panels multiple popout>
              <v-expansion-panel v-for="(shareInfo,index) in shareInfoArr" :key="index">
                <v-expansion-panel-header>
                  {{shareInfo.name}}
                  <v-spacer></v-spacer>
                  <v-responsive max-width="110"><v-icon size="1rem" class="mr-2">mdi-sleep</v-icon>{{shareInfo.createTime}}</v-responsive>
                  
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                      <div key="b">
          <v-row>
            <v-icon class="d-none d-sm-inline" color="blue lighten-2" size="96"
              >mdi-file-download</v-icon
            >
            <v-col cols="12" sm="9" md="8">
              <v-row>
                <v-col cols="12" class="text-h5">名称：{{shareInfo.name}}</v-col>
                <v-col cols="12">大小：{{size(shareInfo.size)}}</v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0 d-flex flex-md-column justify-space-between align-end">
              <v-btn color="primary" x-large rounded @click="openTheWindow(shareInfo.url)">提取</v-btn>
              
            </v-col>
          </v-row>
          <v-divider class="my-5"></v-divider>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-textarea rows="11" v-if="shareInfo.text" outlined v-model="shareInfo.text"></v-textarea>
                <v-img
                v-else
                :aspect-ratio="16/9"
                 contain
                  :src="shareInfo.url"
                >
                  <span class="rounded primary pa-1 white--text"
                    >请提取查看原文件</span
                  >
                  <template slot="placeholder">
                    <div style="height: 100%">
                      <div
                        style="height: 100%"
                        class="my-auto d-flex align-center justify-center text--secondary text-h6"
                      >
                        没有预览
                      </div>
                    </div>
                  </template>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>


export default {
  components: {
    
  },
  data: () => ({
    name:null,
    shareInfoArr:null,
    page: 1,
    funcBtns: [
      {
        icon: "mdi-progress-clock",
        title: "添加定时苏醒资料",
        tip: "资料会先加密并进入沉睡池，到达指定时间后将解密并公布到苏醒池",
        to: "/newData?mode=time",
      },
      {
        icon: "mdi-progress-check",
        title: "添加确认苏醒资料1",
        tip: "资料会先加密并进入沉睡池，待到确认苏醒后将解密并公布到苏醒池",
        to: "/newData?mode=time",
      },
      {
        icon: "mdi-progress-check",
        title: "添加确认苏醒资料2",
        tip: "资料会先加密并进入沉睡池，待到确认苏醒后将解密并公布到苏醒池",
        to: "/newData?mode=time",
      },
      {
        icon: "mdi-progress-check",
        title: "添加确认苏醒资料3",
        tip: "资料会先加密并进入沉睡池，待到确认苏醒后将解密并公布到苏醒池",
        to: "/newData?mode=time",
      },
    ],
    pageItem: 2,
    
  }),
  computed: {
    onShowBtns() {
      let arr = this.funcBtns.slice(
        (this.page - 1) * this.pageItem,
        this.page * this.pageItem
      );
      return arr;
    },

  },
  created () {
    console.log("more")
    this.name=localStorage.getItem("name")
    this.init()
  },
  methods: {
    init() {
     console.log("init",this.name)
      window.uni.bcc.call({
      url:"api/user/getShareInfoArr",
      data:{
        name:this.name
      },
      success:(res)=>{
        console.log(res)
        this.shareInfoArr=res
      },
      fail:()=>{
        // console.log(res)
      }
    })
    
    },
    size(size){
      if (size<1024) {
        return size+" B"
      }else if (size<Math.pow(1024,2)) {
        return Math.round(size/1024*100)/100+" KB"
      }else if(size<Math.pow(1024,3)){
        return Math.round(size/Math.pow(1024,2)*100)/100+" MB"
      }
      return Math.round(size/Math.pow(1024,3)*100)/100+" GB"
    },
  },
  openTheWindow(url){
    window.open(url);
  }
};
</script>
