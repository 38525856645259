<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="2" lg="1">
        <v-sheet elevation="4" rounded="lg" class="mt-1 v-btn--fixed d-none d-sm-flex">
          <!-- 左列 -->
          <v-tabs
            v-model="tab"
            slider-color="primary"
            center-active
            vertical
            show-arrows
            class="py-2"
          >
            <v-tab v-for="item in tabItems" :key="item.title">
              
                
                  <v-row>
                    <v-icon class="mr-2">{{ item.icon }} </v-icon>
                
                {{item.title}}
                  </v-row>
              
            </v-tab>
          </v-tabs>
        </v-sheet>
        <v-sheet elevation="2" rounded="lg" class="d-flex d-sm-none">
          <!-- 上行 -->
          <v-tabs
            v-model="tab"
            slider-color="primary"
            center-active
            show-arrows
            icons-and-text
            class="px-2"
          >
            <v-tab v-for="item in tabItems" :key="item.title">
              {{ item.title }}
              <v-icon>{{ item.icon }}</v-icon>
            </v-tab>
          </v-tabs>
        </v-sheet>
      </v-col>

      <v-col>
        <v-tabs-items
          v-model="tab"
          transition="scale-transition"
          class="pa-1 mainbg"
        >
        
          <v-tab-item>
            <share />
          </v-tab-item>

          <v-tab-item>
            <receive />
          </v-tab-item>

          <v-tab-item>
            <sleep></sleep>
          </v-tab-item>

          <v-tab-item>
            <more></more>
          </v-tab-item>

        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import share from '../components/Home/share'
import receive from '../components/Home/receive'
import sleep from '../components/Home/sleep'
import more from '../components/Home/more'

export default {
  components: {
    share,
    receive,
    sleep,
    more,
  },
  data: () => ({
    tab: 0,
    tabItems: [
      { icon: "mdi-send-circle", title: "分享" },
      { icon: "mdi-mailbox-up", title: "接收" },
      { icon: "mdi-weather-night", title: "沉睡池" },
      { icon: "mdi-dots-horizontal-circle", title: "功能" },
    ],
  }),
  methods: {},
};
</script>