<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="text-h5"> 添加资料 </v-card-title>
          <v-card-subtitle>- 定时苏醒</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col>
                <v-col>
                  <v-text-field
                    v-model="form.name"
                    outlined
                    label="请输入名称"
                    counter="20"
                    dense
                    :loading="false"
                    :rules="[rules.required, rules.counter]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="form.name"
                    label="请输入简介"
                    counter="25"
                    dense
                    :loading="false"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-textarea outlined dense label="内容"></v-textarea>
                </v-col>
              </v-col>
              <v-col class="d-none d-sm-block" cols="12" sm="1" md="3"> </v-col>
              <v-divider vertical class="d-none d-sm-block"></v-divider>
              <v-col cols="12" sm="3"> ds </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" to="/">return</v-btn>
            <v-btn color="primary" @click="gett">{{ d }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      d: "value",
      form: {
        name: "",
        time: new Date(),
      },
      rules: {
        required: (value) => !!value || "不能为空！",
        counter: (value) => value.length <= 20 || "超出范围！",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  methods: {
    gett() {
      this.d = "loading...";
      this.axios
        .get(
          "https://4c9930ea-d212-46a8-af1b-d5893b6740bc.bspapp.com/http/api/swiperList/list"
        )
        .then((response) => {
          console.log(response.data);
          this.d = new Date();
        });
    },
  },
};
</script>
