<template>
  <v-app>
    <v-app-bar app>
      <v-app-bar-nav-icon
        class="d-flex d-sm-none"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <router-link to="/" class="text-decoration-none text--primary"
        ><v-toolbar-title>MIJUE - deYdata.</v-toolbar-title></router-link
      >

      <v-spacer></v-spacer>

      <v-responsive max-width="200" class="d-none d-sm-flex">
        <v-text-field
          dense
          flat
          hide-details
          solo-inverted
          label="搜索"
          prepend-inner-icon="mdi-cloud-search"
        ></v-text-field>
      </v-responsive>
      <avatar />
      <showMode />
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" bottom temporary>
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              dense
              flat
              hide-details
              solo-inverted
              label="搜索"
              prepend-inner-icon="mdi-cloud-search"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:append> </template>
    </v-navigation-drawer>
    <v-main class="mainbg">
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import avatar from "./components/navBar/avatar";
import showMode from "./components/navBar/showMode";
export default {
  components: {
    avatar,
    showMode,
  },
  data: () => ({
    drawer: false,
  }),
};
</script>