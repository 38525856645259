<template>
  <v-sheet elevation="2" rounded>
    <v-container style="min-height: 70vh" class="d-flex flex-column">
      <v-fade-transition mode="out-in">
        <v-row key="a" v-if="detail == false" class="align-content-center">
          <v-col>
            <v-row justify="center">
              <v-col cols="6" md="4">
                <v-text-field
                  outlined
                  label="分享密码"
                  v-model="sharePwd"
                  @keyup.enter="sendSharePwd"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="6" md="4"
                ><v-btn
                  :loading="loading"
                  color="primary"
                  rounded
                  x-large
                  block
                  @click="sendSharePwd"
                  class="mt-md-5 mb-md-5"
                  >确认</v-btn
                ></v-col
              >
            </v-row>
            <v-snackbar v-model="snackbar" timeout="4000" absolute>
              密码错误或已失效，请重新输入！
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="primary"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  关闭
                </v-btn>
              </template>
            </v-snackbar>
          </v-col>
        </v-row>
        <div key="b" v-else>
          <v-row>
            <v-icon class="d-none d-sm-inline" color="blue lighten-2" size="96"
              >mdi-file-download</v-icon
            >
            <v-col cols="12" sm="9" md="8">
              <v-row>
                <v-col cols="12" class="text-h5"
                  >名称：{{ shareInfo.name }}</v-col
                >
                <v-col cols="12">大小：{{ size(shareInfo.size) }}</v-col>
              </v-row>
            </v-col>
            <v-col
              class="pb-0 d-flex flex-md-column justify-space-between align-end"
            >
              <v-btn
                color="primary"
                x-large
                rounded
                @click="openTheWindow(shareInfo.url)"
                >提取</v-btn
              >
              <v-btn
                class="ml-5 mt-2"
                color="primary"
                outlined
                small
                rounded
                @click="detail = false"
                ><v-icon left>mdi-arrow-left-drop-circle-outline</v-icon
                >返回</v-btn
              >
            </v-col>
          </v-row>
          <v-divider class="my-5"></v-divider>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-textarea
                  rows="11"
                  v-if="shareInfo.text"
                  outlined
                  v-model="shareInfo.text"
                ></v-textarea>
                <v-img v-else :aspect-ratio="16 / 9" contain :src="imgUrl">
                  <span class="rounded primary pa-1 white--text"
                    >请提取查看原文件</span
                  >
                  <template slot="placeholder">
                    <div style="height: 100%">
                      <div
                        style="height: 100%"
                        class="my-auto d-flex align-center justify-center text--secondary text-h6"
                      >
                        没有预览
                      </div>
                    </div>
                  </template>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-fade-transition>
    </v-container>
  </v-sheet>
</template>

<script>
// import JsFileDownloader from "js-file-downloader";
export default {
  data() {
    return {
      sharePwd: null,
      loading: false,
      detail: false,
      shareInfo: null,
      imgUrl: null,
      snackbar: false,
    };
  },
  methods: {
    sendSharePwd() {
      this.loading = true;
      window.uni.bcc.call({
        url: "api/index/getShareInfo",
        data: {
          sharePwd: this.sharePwd,
        },
        success: (res) => {
          if (localStorage.getItem("name")) {
            console.log(localStorage.getItem("name"));
            window.uni.bcc.call({
              url: "api/user/putSharePwd",
              data: {
                name: localStorage.getItem("name"),
                sharePwd: this.sharePwd,
              },
              success: (e) => {
                console.log(e);
              },
              fail: (e) => {
                console.log(e);
              },
            });
          }

          console.log(res);
          this.loading = false;
          this.shareInfo = res.shareInfo;
          this.imgUrl = this.isImg(this.shareInfo.url)
            ? this.shareInfo.url
            : null;
          this.detail = true;

          // this.readFile(this.shareInfo.url);
        },
        fail: (res) => {
          console.log(res);
          this.snackbar = true;
          this.loading = false;
        },
      });
    },
    openTheWindow(url) {
      window.open(url);
      // new JsFileDownloader({
      //   url: url,
      //   headers:[
      //     {  name:'Content-Disposition' ,value:'attachment' }
      //   ]
      //   // forceDesktopMode:true,

      // })
    },
    isImg(url) {
      const exptxt = /\.(gif|jpg|jpeg|png|bmp|webp)$/i;
      return exptxt.test(url);
    },
    size(size) {
      if (size < 1024) {
        return size + " B";
      } else if (size < Math.pow(1024, 2)) {
        return Math.round((size / 1024) * 100) / 100 + " KB";
      } else if (size < Math.pow(1024, 3)) {
        return Math.round((size / Math.pow(1024, 2)) * 100) / 100 + " MB";
      }
      return Math.round((size / Math.pow(1024, 3)) * 100) / 100 + " GB";
    },
    // readFile(url){
    //   this.axios
    //     .get(
    //       url
    //     )
    //     .then((response) => {
    //       console.log(response.data);
    //       // this.d = new Date();
    //     });
    // }
  },
};
</script>
